var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        { staticClass: "rmk_pop" },
        [
          _vm.rmkComponent
            ? _c(_vm.rmkComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.parentInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(" 나의 할인쿠폰 "),
        _c(
          "a",
          {
            staticClass: "button",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.pdfDownload("쿠폰 사용 FAQ_241118.pdf")
              },
            },
          },
          [_vm._v(" [할인쿠폰 FAQ 다운로드 하기 (CLICK)]")]
        ),
      ]),
      _c("form", { attrs: { id: "frm2" } }, [
        _c("div", { staticClass: "content_box beforetab" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _c("colgroup", [
              _c("col", {
                attrs: { width: [_vm.serviceCtrCd === "KR" ? "60px" : "80px"] },
              }),
              _c("col", { attrs: { width: "150px" } }),
              _c("col", { attrs: { width: "324px" } }),
              _c("col", { attrs: { width: "50px" } }),
              _c("col", { attrs: { width: "150px" } }),
              _c("col", { attrs: { width: "180px" } }),
              _c("col", { attrs: { width: "50px" } }),
              _c("col", { attrs: { width: "120px" } }),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.001")))]),
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "polPol",
                        "ctr-cd": _vm.params.porCtrCd,
                        "plc-cd": _vm.params.porPlcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePol },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.002")))]),
                _c(
                  "td",
                  { attrs: { colspan: "4" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podPod",
                        "ctr-cd": _vm.params.dlyCtrCd,
                        "plc-cd": _vm.params.dlyPlcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePod },
                    }),
                  ],
                  1
                ),
                _c("td", { staticClass: "text_right" }),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.003")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.kind,
                          expression: "params.kind",
                        },
                      ],
                      staticClass: "wid150",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.params,
                            "kind",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "R" } }, [
                        _vm._v("ETD 기준"),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "td",
                  { attrs: { colspan: "3" } },
                  [
                    _c("e-date-range-picker", {
                      attrs: {
                        id: "date_picker",
                        sdate: _vm.params.fromDt,
                        edate: _vm.params.toDt,
                        "is-debug": true,
                      },
                      on: { change: _vm.changeDateRange },
                    }),
                  ],
                  1
                ),
                _c(
                  "th",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "padding-right": "15px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.COUP100.014")))]
                ),
                _c("td", { attrs: { colspan: "2" } }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.cntrTyp,
                          expression: "params.cntrTyp",
                        },
                      ],
                      staticClass: "wid150",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.params,
                            "cntrTyp",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _c("option", { attrs: { value: "GP" } }, [
                        _vm._v("GP, HC"),
                      ]),
                      _c("option", { attrs: { value: "TK" } }, [_vm._v("TK")]),
                      _c("option", { attrs: { value: "RF" } }, [_vm._v("RF")]),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.searchMyCoupon(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.013")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "18px",
              "font-weight": "bolder",
              color: "red",
            },
          },
          [
            _vm._v(
              "※ 시작일~만료일 이내에 출항한 건들에 대하여 INVIOCE 발행시 쿠폰 적용 가능합니다."
            ),
          ]
        ),
        _vm._m(0),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box" }, [
      _c("div", {
        staticClass: "mt5",
        staticStyle: { width: "100%", height: "650px" },
        attrs: { id: "realgrid" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }