var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking_new_area capture_area" },
    [
      _c(
        "form",
        {
          attrs: { id: "frm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.saveFlag === "ADD"
            ? _c("span", { staticClass: "booking_info" }, [
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.046")))]),
                    _c("span", [_vm._v(_vm._s(_vm.auth.userName))]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("TEL")]),
                    _c("span", [_vm._v(_vm._s(_vm.usrInfo.usrTel))]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("FAX")]),
                    _c("span", [_vm._v(_vm._s(_vm.usrInfo.usrFax))]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.saveFlag === "ADD"
            ? _c("div", { staticClass: "content_box t2" }, [
                _c("div", { staticClass: "booking_call_wrap" }, [
                  _c("div", { staticClass: "call_search" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.vueMode,
                          expression: "vueMode",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.templateCd,
                          expression: "regParams.templateCd",
                        },
                      ],
                      attrs: { type: "radio", id: "templateCd_1", value: "A" },
                      domProps: {
                        checked: _vm._q(_vm.regParams.templateCd, "A"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.regParams, "templateCd", "A")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "templateCd_1" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.504"))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.templateNo,
                          expression: "templateNo",
                        },
                      ],
                      class: _vm.urlOrNot ? "dsm_info" : "dsm_info",
                      attrs: { type: "text", placeholder: "Booking No." },
                      domProps: { value: _vm.templateNo },
                      on: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.templateEnter.apply(null, arguments)
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.templateNo = $event.target.value
                          },
                          function ($event) {
                            _vm.templateNo = _vm.templateNo.toUpperCase()
                          },
                        ],
                        click: function ($event) {
                          _vm.regParams.templateCd = "A"
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "search_btn01",
                        attrs: { type: "button" },
                        on: {
                          click: [
                            function ($event) {
                              $event.preventDefault()
                              return _vm.templateSearch()
                            },
                            function ($event) {
                              _vm.regParams.templateCd = "A"
                            },
                          ],
                        },
                      },
                      [
                        _c("span", { staticClass: "offscreen" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.121"))),
                        ]),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.vueMode,
                          expression: "vueMode",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.templateCd,
                          expression: "regParams.templateCd",
                        },
                      ],
                      attrs: { type: "radio", id: "templateCd_2", value: "B" },
                      domProps: {
                        checked: _vm._q(_vm.regParams.templateCd, "B"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.regParams, "templateCd", "B")
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vueMode,
                            expression: "vueMode",
                          },
                        ],
                        staticStyle: { "margin-left": "20px" },
                        attrs: { for: "templateCd_2" },
                      },
                      [_vm._v("Template")]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vueMode,
                            expression: "vueMode",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.templateNo_2,
                            expression: "templateNo_2",
                          },
                        ],
                        class: _vm.urlOrNot ? "dsm_info" : "dsm_info",
                        staticStyle: { width: "200px", "margin-left": "20px" },
                        attrs: { id: "templateList" },
                        on: {
                          click: function ($event) {
                            _vm.regParams.templateCd = "B"
                          },
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.templateNo_2 = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(
                            " " + _vm._s(this.$t("msg.CSBL200.078")) + " "
                          ),
                        ]),
                        _vm._l(_vm.templateList, function (vo) {
                          return _c(
                            "option",
                            { key: vo.tplRno, domProps: { value: vo.tplRno } },
                            [_vm._v(" " + _vm._s(vo.tplNm) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "button blue sh ml15",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setTemplateInfo()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("btn.CMBTK010")))]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vueMode,
                            expression: "vueMode",
                          },
                        ],
                        staticClass: "button blue sh",
                        staticStyle: { "margin-left": "5px" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.deleteTemplate()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("art.CMBA660.007")))]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.saveFlag === "EDIT"
            ? _c("div", { staticClass: "content_box beforetab" }, [
                _c("table", { staticClass: "tbl_search" }, [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Booking No"),
                      ]),
                      _c("td", { staticClass: "text_left" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.bkgNo,
                                expression: "regParams.bkgNo",
                              },
                            ],
                            class: _vm.urlOrNot ? "dsm_info" : "dsm_info",
                            staticStyle: { width: "85%" },
                            attrs: { id: "bkgNoList" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "bkgNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { domProps: { value: _vm.regParams.bkgNo } },
                              [_vm._v(" " + _vm._s(_vm.regParams.bkgNo) + " ")]
                            ),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn_copy ml3",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnCopy()
                              },
                            },
                          },
                          [_vm._v("copy")]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "button sm mr10",
                            on: {
                              click: function ($event) {
                                return _vm.fnCommonBtnAction("LOG")
                              },
                            },
                          },
                          [_vm._v("Log " + _vm._s(_vm.$t("btn.CMBTK011")))]
                        ),
                        _vm.$ekmtcCommon.isNotEmpty(_vm.statusMsg)
                          ? _c(
                              "span",
                              { class: "box_info " + _vm.statusClass },
                              [
                                _c("span", { staticClass: "note" }, [
                                  _vm._v(_vm._s(_vm.statusMsg)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content_box mt10 booking_content_area" },
            [
              _c("div", { staticClass: "lybox ly_btn" }, [
                _c("div", { staticClass: "ly_column left_col col_5" }, [
                  _c("div", { staticClass: "ly_col_cont" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(this.$t("msg.CSBK100.124")) + " "),
                    ]),
                    _c("table", { staticClass: "tbl_row tbl_bkg_charger" }, [
                      _vm._m(1),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(this.$t("msg.CSBK100.126"))),
                          ]),
                          _c("td", { staticClass: "position_relative" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.picNmh,
                                    expression: "picNmh",
                                  },
                                ],
                                staticClass: "col_3",
                                attrs: { id: "picNmh" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.picNmh = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.changePicNm,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(this.$t("msg.CSBK100.050")) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.cstInfoList, function (vo) {
                                  return _c(
                                    "option",
                                    {
                                      key: vo.picNo,
                                      domProps: { value: vo.picNo },
                                    },
                                    [_vm._v(" " + _vm._s(vo.picNm) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.bizPicNm,
                                  expression: "regParams.bizPicNm",
                                },
                              ],
                              staticClass: "inp_space70",
                              attrs: {
                                id: "bizPicNm",
                                type: "text",
                                maxlength: "30",
                              },
                              domProps: { value: _vm.regParams.bizPicNm },
                              on: {
                                keyup: function ($event) {
                                  return _vm.checkValidationCharger(
                                    "bizPicNm",
                                    "KEYUP"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "bizPicNm",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.148")))]),
                          _c(
                            "td",
                            { staticClass: "position_relative" },
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "bizPicTelPlcNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicTelPlcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicTelPlcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicTelPlcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicTelPlcNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "bizPicTelOfcNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicTelOfcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicTelOfcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicTelOfcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicTelOfcNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("FAX")]),
                          _c(
                            "td",
                            { staticClass: "position_relative" },
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "bizPicFaxPlcNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicFaxPlcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicFaxPlcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicFaxPlcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicFaxPlcNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "bizPicFaxOfcNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicFaxOfcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicFaxOfcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicFaxOfcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicFaxOfcNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.149")))]),
                          _c(
                            "td",
                            { staticClass: "position_relative" },
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "bizPicMbpBsnNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicMbpBsnNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicMbpBsnNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicMbpBsnNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicMbpBsnNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "bizPicMbpOfcNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "bizPicMbpOfcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.bizPicMbpOfcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "bizPicMbpOfcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.bizPicMbpOfcNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("email")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.bizPicEmlAddr,
                                  expression: "regParams.bizPicEmlAddr",
                                },
                              ],
                              staticClass: "inp_98",
                              attrs: {
                                id: "bizPicEmlAddr",
                                type: "text",
                                maxlength: "100",
                                placeholder: _vm.$t("msg.CSBK100.152"),
                              },
                              domProps: { value: _vm.regParams.bizPicEmlAddr },
                              on: {
                                blur: function ($event) {
                                  return _vm.checkValidationCharger(
                                    "bizPicEmlAddr",
                                    "KEYUP"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "bizPicEmlAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_col" }, [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon arrow",
                      on: { click: _vm.changeDocInfo },
                    },
                    [_vm._v("arrow")]
                  ),
                ]),
                _c("div", { staticClass: "ly_column right_col col_5" }, [
                  _c("div", { staticClass: "ly_col_cont" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.125")) + " "),
                    ]),
                    _c("table", { staticClass: "tbl_row tbl_bkg_charger" }, [
                      _vm._m(2),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.126")))]),
                          _c("td", { staticClass: "position_relative" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.docPicNmh,
                                    expression: "docPicNmh",
                                  },
                                ],
                                staticClass: "col_3",
                                attrs: { id: "docPicNmh" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.docPicNmh = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.changeDocPicNmh,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(this.$t("msg.CSBK100.050")) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.cstInfoList, function (vo) {
                                  return _c(
                                    "option",
                                    {
                                      key: vo.picNo,
                                      domProps: { value: vo.picNo },
                                    },
                                    [_vm._v(" " + _vm._s(vo.picNm) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.docPicNm,
                                  expression: "regParams.docPicNm",
                                },
                              ],
                              staticClass: "inp_space70",
                              attrs: {
                                id: "docPicNm",
                                type: "text",
                                maxlength: "30",
                              },
                              domProps: { value: _vm.regParams.docPicNm },
                              on: {
                                keyup: function ($event) {
                                  return _vm.checkValidationCharger(
                                    "docPicNm",
                                    "KEYUP"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "docPicNm",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.148")))]),
                          _c(
                            "td",
                            { staticClass: "position_relative" },
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "docPicTelPlcNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicTelPlcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicTelPlcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "docPicTelPlcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.docPicTelPlcNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "docPicTelNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicTelNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicTelNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "docPicTelNo", $$v)
                                  },
                                  expression: "regParams.docPicTelNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("FAX")]),
                          _c(
                            "td",
                            { staticClass: "position_relative" },
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "docPicFaxPlcNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicFaxPlcNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicFaxPlcNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "docPicFaxPlcNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.docPicFaxPlcNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "docPicFaxNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicFaxNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicFaxNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "docPicFaxNo", $$v)
                                  },
                                  expression: "regParams.docPicFaxNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.149")))]),
                          _c(
                            "td",
                            [
                              _c("e-input-number", {
                                staticClass: "col_3",
                                attrs: {
                                  id: "docPicMbpBsnNo",
                                  "is-phone": true,
                                  maxlength: "4",
                                  disabled: _vm.userHkSg === "Y",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicMbpBsnNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicMbpBsnNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.regParams,
                                      "docPicMbpBsnNo",
                                      $$v
                                    )
                                  },
                                  expression: "regParams.docPicMbpBsnNo",
                                },
                              }),
                              _c("e-input-number", {
                                staticClass: "inp_space70",
                                attrs: {
                                  id: "docPicMbpNo",
                                  "is-phone": true,
                                  maxlength: "12",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkValidationCharger(
                                      "docPicMbpNo",
                                      "KEYUP"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.docPicMbpNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "docPicMbpNo", $$v)
                                  },
                                  expression: "regParams.docPicMbpNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("email")]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.docPicEmlAddr,
                                  expression: "regParams.docPicEmlAddr",
                                },
                              ],
                              staticClass: "inp_98",
                              attrs: {
                                id: "docPicEmlAddr",
                                type: "text",
                                placeholder: _vm.$t("msg.CSBK100.152"),
                                maxlength: "100",
                              },
                              domProps: { value: _vm.regParams.docPicEmlAddr },
                              on: {
                                blur: function ($event) {
                                  return _vm.checkValidationCharger(
                                    "docPicEmlAddr",
                                    "KEYUP"
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "docPicEmlAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("p", { staticClass: "txt_desc" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.150")) + " "),
                _c("br"),
                _vm.memberDetail.userCtrCd === "JP"
                  ? _c("span", [
                      _vm._v(
                        " 着信通知メールはプロフィール設定を参照してください。(https://www.ekmtc.com/index.html#/my-info/setting/profile) "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "flex_box mt20" }, [
                _vm.regParams.mtyPkupUsagYn !== "Y"
                  ? _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.151")) + " "),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "sc_box scd_btn_area" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPkupUsagYn,
                          expression: "isPkupUsagYn",
                        },
                      ],
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.mtyPkupUsagYn,
                            expression: "regParams.mtyPkupUsagYn",
                          },
                        ],
                        attrs: {
                          id: "mtyPkupUsagYn",
                          type: "checkbox",
                          "true-value": "Y",
                          "false-value": "N",
                          disabled: _vm.saveFlag === "EDIT",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.regParams.mtyPkupUsagYn)
                            ? _vm._i(_vm.regParams.mtyPkupUsagYn, null) > -1
                            : _vm._q(_vm.regParams.mtyPkupUsagYn, "Y"),
                        },
                        on: {
                          click: _vm.checkMtyPkupUsagYn,
                          change: function ($event) {
                            var $$a = _vm.regParams.mtyPkupUsagYn,
                              $$el = $event.target,
                              $$c = $$el.checked ? "Y" : "N"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.regParams,
                                    "mtyPkupUsagYn",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.regParams,
                                    "mtyPkupUsagYn",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.regParams, "mtyPkupUsagYn", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "mtyPkupUsagYn" } }, [
                        _vm._v("Pick Up Booking"),
                      ]),
                    ]
                  ),
                  _vm.regParams.mtyPkupUsagYn !== "Y" && _vm.schdBtnYn !== "N"
                    ? _c("span", { staticClass: "ml_auto" }, [
                        _c(
                          "a",
                          {
                            class: _vm.setScheduleBtnClass(),
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.searchSchedule.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$ekmtcCommon.isEmpty(
                                  _vm.regParams.hidCS008I
                                )
                                  ? _vm.$t("msg.CSBK100.153")
                                  : _vm.$t("msg.CSBK100.154")
                              )
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.regParams.mtyPkupUsagYn !== "Y"
                ? _c("booking-schedule-info", {
                    ref: "schdInfo",
                    attrs: {
                      "leg-trml-fixd-yn": _vm.regParams.legTrmlFixdYn,
                      "leg-fix-show": _vm.isLegFixShow,
                      "bkg-no": _vm.regParams.bkgNo,
                      "disabled-yn": _vm.disabledYn,
                      "is-url": _vm.urlOrNot,
                    },
                  })
                : _vm._e(),
              _vm.regParams.mtyPkupUsagYn !== "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.158")) + " "),
                    ]),
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(3),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.011"))),
                            ]),
                            _c(
                              "td",
                              [
                                _c("e-auto-complete-cst-enm2", {
                                  staticClass:
                                    "col_5 auto-area position_relative",
                                  attrs: {
                                    "ctr-cd": _vm.usrCtrCd,
                                    "cd-nm": _vm.regParams.actShprCstEnm,
                                    id: "actShprCstEnm",
                                    disabled: _vm.regParams.consYn === "Y",
                                  },
                                  on: {
                                    change: _vm.changeActShprCstENm,
                                    input: function ($event) {
                                      return _vm.checkValidationCargoInfo(
                                        "actShprCstEnm"
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.consReuseChk()
                                    },
                                  },
                                }),
                                _vm.lang === "KOR"
                                  ? _c("span", { staticClass: "float_right" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.reuseYn,
                                            expression: "regParams.reuseYn",
                                          },
                                        ],
                                        attrs: {
                                          id: "c2",
                                          type: "checkbox",
                                          "true-value": "Y",
                                          "false-value": "N",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.regParams.reuseYn
                                          )
                                            ? _vm._i(
                                                _vm.regParams.reuseYn,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.regParams.reuseYn,
                                                "Y"
                                              ),
                                        },
                                        on: {
                                          click: _vm.changeReuseYn,
                                          change: function ($event) {
                                            var $$a = _vm.regParams.reuseYn,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "Y" : "N"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "reuseYn",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "reuseYn",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.regParams,
                                                "reuseYn",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v("   "),
                                      _vm._m(4),
                                    ])
                                  : _vm._e(),
                                _vm.lang === "KOR"
                                  ? _c("span", { staticClass: "float_right" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.consYn,
                                            expression: "regParams.consYn",
                                          },
                                        ],
                                        attrs: {
                                          id: "c1",
                                          type: "checkbox",
                                          "true-value": "Y",
                                          "false-value": "N",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.regParams.consYn
                                          )
                                            ? _vm._i(
                                                _vm.regParams.consYn,
                                                null
                                              ) > -1
                                            : _vm._q(_vm.regParams.consYn, "Y"),
                                        },
                                        on: {
                                          click: _vm.changeConsYn,
                                          change: function ($event) {
                                            var $$a = _vm.regParams.consYn,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "Y" : "N"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "consYn",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "consYn",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.regParams,
                                                "consYn",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("label", { attrs: { for: "c1" } }, [
                                        _c("span"),
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.159"))
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("th", [_vm._v("Cargo Weight")]),
                            _c("td", [
                              _c(
                                "span",
                                { staticClass: "wg" },
                                [
                                  _c("e-input-number", {
                                    attrs: {
                                      id: "grsWtTon",
                                      "is-comma": true,
                                      "input-class": "wid80",
                                      disabled: _vm.isKg,
                                      point: 3,
                                      maxlength: "23",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeGrsWt($event, "TON")
                                      },
                                    },
                                    model: {
                                      value: _vm.grsWtTon,
                                      callback: function ($$v) {
                                        _vm.grsWtTon = $$v
                                      },
                                      expression: "grsWtTon",
                                    },
                                  }),
                                  _vm._v(" ton "),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "color_gray span_grs" },
                                [_vm._v("/")]
                              ),
                              _c(
                                "span",
                                { staticClass: "wg" },
                                [
                                  _c("e-input-number", {
                                    attrs: {
                                      "is-comma": true,
                                      id: "grsWtKg",
                                      "input-class": "wid80",
                                      disabled: _vm.isTon,
                                      maxlength: "30",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeGrsWt($event, "KG")
                                      },
                                    },
                                    model: {
                                      value: _vm.grsWtKg,
                                      callback: function ($$v) {
                                        _vm.grsWtKg = $$v
                                      },
                                      expression: "grsWtKg",
                                    },
                                  }),
                                  _vm._v(" kg "),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Ocean Freight"),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.frtPncCd,
                                    expression: "regParams.frtPncCd",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "frtPncCd_1",
                                  value: "P",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.regParams.frtPncCd, "P"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFrtPncCd($event)
                                  },
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.regParams,
                                      "frtPncCd",
                                      "P"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "frtPncCd_1" } }, [
                                _vm._v("PREPAID"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.frtPncCd,
                                    expression: "regParams.frtPncCd",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "frtPncCd_2",
                                  value: "C",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.regParams.frtPncCd, "C"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFrtPncCd($event)
                                  },
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.regParams,
                                      "frtPncCd",
                                      "C"
                                    )
                                  },
                                },
                              }),
                              _c("label", { attrs: { for: "frtPncCd_2" } }, [
                                _vm._v("COLLECT"),
                              ]),
                            ]),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("SC No."),
                            ]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.cneCstEnm,
                                    expression: "regParams.cneCstEnm",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  maxlength: "30",
                                  id: "cneCstEnm",
                                  disabled: _vm.regParams.frtPncCd !== "C",
                                  placeholder:
                                    _vm.regParams.frtPncCd !== "C"
                                      ? "For COLLECT term only"
                                      : "",
                                },
                                domProps: { value: _vm.regParams.cneCstEnm },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkValidationCargoInfo(
                                      "cneCstEnm"
                                    )
                                  },
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "cneCstEnm",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      _vm.regParams.cneCstEnm =
                                        _vm.regParams.cneCstEnm.toUpperCase()
                                    },
                                  ],
                                  blur: function ($event) {
                                    return _vm.findIotCntrYn("S")
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Commodity")]),
                            _c(
                              "td",
                              [
                                _c("e-auto-complete-commodity", {
                                  staticClass:
                                    "col_5 auto-area position_relative",
                                  attrs: {
                                    "ctr-cd": _vm.usrCtrCd,
                                    "cd-nm": _vm.regParams.cmdtDsc,
                                    disabled: _vm.regParams.emptyFlag === "E",
                                    id: "cmdtDsc",
                                    "focus-force": true,
                                  },
                                  on: {
                                    change: _vm.changeCmdtDsc,
                                    input: function ($event) {
                                      return _vm.checkValidationCargoInfo(
                                        "cmdtDsc"
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "float_right" }, [
                                  ["SG", "MY"].includes(
                                    _vm.memberDetail.userCtrCd
                                  )
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.udab,
                                            expression: "regParams.udab",
                                          },
                                        ],
                                        attrs: {
                                          id: "udab",
                                          type: "checkbox",
                                          "true-value": "Y",
                                          "false-value": "N",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.regParams.udab
                                          )
                                            ? _vm._i(_vm.regParams.udab, null) >
                                              -1
                                            : _vm._q(_vm.regParams.udab, "Y"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.regParams.udab,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "Y" : "N"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "udab",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "udab",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.regParams,
                                                "udab",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  ["SG", "MY"].includes(
                                    _vm.memberDetail.userCtrCd
                                  )
                                    ? _c("label", { attrs: { for: "udab" } }, [
                                        _vm._v(" UDAB "),
                                      ])
                                    : _vm._e(),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.emptyFlag,
                                        expression: "regParams.emptyFlag",
                                      },
                                    ],
                                    attrs: {
                                      id: "emptyFlag",
                                      type: "checkbox",
                                      "true-value": "E",
                                      "false-value": "F",
                                      disabled: _vm.isDisabledEmptyFlag(),
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.regParams.emptyFlag
                                      )
                                        ? _vm._i(
                                            _vm.regParams.emptyFlag,
                                            null
                                          ) > -1
                                        : _vm._q(_vm.regParams.emptyFlag, "E"),
                                    },
                                    on: {
                                      click: _vm.changeEmptyFlag,
                                      change: function ($event) {
                                        var $$a = _vm.regParams.emptyFlag,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? "E" : "F"
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.regParams,
                                                "emptyFlag",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.regParams,
                                                "emptyFlag",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.regParams,
                                            "emptyFlag",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "emptyFlag" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.072"))),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("th", [_vm._v("Term")]),
                            _vm.cstCatCd !== "Y"
                              ? _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.polShtmCd,
                                          expression: "regParams.polShtmCd",
                                        },
                                      ],
                                      staticClass: "wid120",
                                      attrs: {
                                        id: "polShtmCd",
                                        disabled: _vm.usrCtrCd === "VN",
                                      },
                                      on: {
                                        click: _vm.clickPolShtm,
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "polShtmCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.changePolShtm,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v("CY"),
                                      ]),
                                      _c("option", { attrs: { value: "02" } }, [
                                        _vm._v("CFS"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.podShtmCd,
                                          expression: "regParams.podShtmCd",
                                        },
                                      ],
                                      staticClass: "wid120 d_inline_block",
                                      attrs: { id: "podShtmCd", disabled: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "podShtmCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v("CY"),
                                      ]),
                                      _c("option", { attrs: { value: "02" } }, [
                                        _vm._v("CFS"),
                                      ]),
                                      _c("option", { attrs: { value: "03" } }, [
                                        _vm._v(_vm._s("CY&CFS")),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.cstCatCd === "Y"
                              ? _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.polShtmCd,
                                          expression: "regParams.polShtmCd",
                                        },
                                      ],
                                      staticClass: "wid120",
                                      attrs: {
                                        id: "polShtmCd",
                                        disabled: _vm.usrCtrCd === "VN",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "polShtmCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v("CY"),
                                      ]),
                                      _c("option", { attrs: { value: "05" } }, [
                                        _vm._v("Tackle"),
                                      ]),
                                      _c("option", { attrs: { value: "06" } }, [
                                        _vm._v("FI"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.podShtmCd,
                                          expression: "regParams.podShtmCd",
                                        },
                                      ],
                                      staticClass: "wid120 d_inline_block",
                                      attrs: { id: "podShtmCd", disabled: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "podShtmCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v("CY"),
                                      ]),
                                      _c("option", { attrs: { value: "05" } }, [
                                        _vm._v("Tackle"),
                                      ]),
                                      _c("option", { attrs: { value: "07" } }, [
                                        _vm._v("FO"),
                                      ]),
                                      _c("option", { attrs: { value: "09" } }, [
                                        _vm._v("T/S Zone"),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "tr",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.regParams.chemicalUploadYn === "Y",
                                  expression:
                                    "regParams.chemicalUploadYn === 'Y'",
                                },
                              ],
                            },
                            [
                              _c(
                                "th",
                                {
                                  staticClass: "bor-left",
                                  attrs: { scope: "row" },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.022")))]
                              ),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.chemicalYn,
                                      expression: "regParams.chemicalYn",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "chemicalY",
                                    name: "chemicalY",
                                    value: "Y",
                                    "true-value": "Y",
                                    "false-value": "N",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.regParams.chemicalYn,
                                      "Y"
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toolTipHide("chemicalY", "Y")
                                    },
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.regParams,
                                        "chemicalYn",
                                        "Y"
                                      )
                                    },
                                  },
                                }),
                                _vm._m(5),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.chemicalYn,
                                      expression: "regParams.chemicalYn",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "chemicalN",
                                    name: "chemicalN",
                                    value: "N",
                                    "true-value": "N",
                                    "false-value": "Y",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.regParams.chemicalYn,
                                      "N"
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toolTipHide("chemicalY", "N")
                                    },
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.regParams,
                                        "chemicalYn",
                                        "N"
                                      )
                                    },
                                  },
                                }),
                                _vm._m(6),
                                _vm._v("   "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.regParams.chemicalYn === "Y",
                                        expression:
                                          "regParams.chemicalYn === 'Y'",
                                      },
                                    ],
                                    staticClass: "button sm",
                                    class: _vm.isChemicalFileUpload()
                                      ? "blue"
                                      : "gray",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUploadPopup("chemical")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.205")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm.usrCtrCd === "PH"
                            ? _c("tr", [
                                _c("th", [_vm._v("Haulier")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.tfcoCstNm,
                                        expression: "regParams.tfcoCstNm",
                                      },
                                    ],
                                    staticClass: "col_8",
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: {
                                      value: _vm.regParams.tfcoCstNm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "tfcoCstNm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.usrCtrCd === "IN"
                            ? _c("tr", [
                                _c("th", [_vm._v("PDA")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.dpstAcCd,
                                        expression: "regParams.dpstAcCd",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "dpstAcCd_1",
                                      value: "01",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.regParams.dpstAcCd,
                                        "01"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.regParams,
                                          "dpstAcCd",
                                          "01"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "dpstAcCd_1" } },
                                    [_vm._v("shipper's")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.dpstAcCd,
                                        expression: "regParams.dpstAcCd",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "dpstAcCd_2",
                                      value: "02",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.regParams.dpstAcCd,
                                        "02"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.regParams,
                                          "dpstAcCd",
                                          "02"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "dpstAcCd_2" } },
                                    [_vm._v("carrier's")]
                                  ),
                                ]),
                                _c("th", [_vm._v("place of stuffing")]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.stuffPlceCd,
                                        expression: "regParams.stuffPlceCd",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "stuffPlceCd_1",
                                      value: "01",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.regParams.stuffPlceCd,
                                        "01"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.regParams,
                                          "stuffPlceCd",
                                          "01"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "stuffPlceCd_1" } },
                                    [_vm._v("factroy")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.stuffPlceCd,
                                        expression: "regParams.stuffPlceCd",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "stuffPlceCd_2",
                                      value: "02",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.regParams.stuffPlceCd,
                                        "02"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.regParams,
                                          "stuffPlceCd",
                                          "02"
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "stuffPlceCd_2" } },
                                    [_vm._v("cfs(ICD)")]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.lang === "JPN" && _vm.fwdrCstCd === "01"
                            ? _c("tr", [
                                _c("th", [_vm._v("Booking Shipper")]),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.bkgShprCstNo,
                                          expression: "regParams.bkgShprCstNo",
                                        },
                                      ],
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "bkgShprCstNo",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.changeBkgShprCstNo,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.050"))
                                        ),
                                      ]),
                                      _vm._l(_vm.jpBkgShprList, function (vo) {
                                        return _c(
                                          "option",
                                          {
                                            key: vo.bkgShprCstNo,
                                            domProps: {
                                              value: vo.bkgShprCstNo,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(vo.bkgShprCstEnm) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.bkgShprCstEnm,
                                        expression: "regParams.bkgShprCstEnm",
                                      },
                                    ],
                                    staticClass: "txt_jp",
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: {
                                      value: _vm.regParams.bkgShprCstEnm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "bkgShprCstEnm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "jp_notice" }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.503"))),
                                  ]),
                                ]),
                                _c("th", [_vm._v("通関業者")]),
                                _c("td", { staticClass: "vat" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.fwdrCstNo,
                                          expression: "regParams.fwdrCstNo",
                                        },
                                      ],
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "fwdrCstNo",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.changeFwdrCstNo,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.050"))
                                        ),
                                      ]),
                                      _vm._l(_vm.jpFwdrList, function (vo) {
                                        return _c(
                                          "option",
                                          {
                                            key: vo.fwdrCstNo,
                                            domProps: { value: vo.fwdrCstNo },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(vo.fwdrCstEnm) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.fwdrCstEnm,
                                        expression: "regParams.fwdrCstEnm",
                                      },
                                    ],
                                    staticClass: "txt_jp",
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: {
                                      value: _vm.regParams.fwdrCstEnm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "fwdrCstEnm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.lang === "JPN" && _vm.fwdrCstCd !== "01"
                            ? _c("tr", [
                                _c("th", [_vm._v("通関業者")]),
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.fwdrCstNo,
                                          expression: "regParams.fwdrCstNo",
                                        },
                                      ],
                                      staticClass: "col_3",
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "fwdrCstNo",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.changeFwdrCstNo,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.050"))
                                        ),
                                      ]),
                                      _vm._l(_vm.jpFwdrList, function (vo) {
                                        return _c(
                                          "option",
                                          {
                                            key: vo.fwdrCstNo,
                                            domProps: { value: vo.fwdrCstNo },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(vo.fwdrCstEnm) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.fwdrCstEnm,
                                        expression: "regParams.fwdrCstEnm",
                                      },
                                    ],
                                    staticClass: "col_3",
                                    attrs: { type: "text", maxlength: "50" },
                                    domProps: {
                                      value: _vm.regParams.fwdrCstEnm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "fwdrCstEnm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.regParams.polCtrCd == "MY"
                            ? [
                                _c("tr", [
                                  _c("th", { attrs: { scope: "row" } }, [
                                    _vm._v("HS CODE"),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "tbl_form" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.hsCd,
                                            expression: "regParams.hsCd",
                                          },
                                        ],
                                        staticStyle: { width: "272px" },
                                        attrs: {
                                          type: "text",
                                          id: "my_hsCd",
                                          maxlength: "10",
                                        },
                                        domProps: { value: _vm.regParams.hsCd },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.myHsCdInputCheck()
                                          },
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.regParams,
                                                "hsCd",
                                                $event.target.value
                                              )
                                            },
                                            function ($event) {
                                              return _vm.checkMyValidation()
                                            },
                                          ],
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("th", [
                                    _vm._v(" Bidding No."),
                                    _c("span", { staticClass: "txt" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.$t("msg.CSBK100.062")) +
                                          ")"
                                      ),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.cstBidgMgrNo,
                                          expression: "regParams.cstBidgMgrNo",
                                        },
                                      ],
                                      attrs: { type: "text", maxlength: "10" },
                                      domProps: {
                                        value: _vm.regParams.cstBidgMgrNo,
                                      },
                                      on: {
                                        change: _vm.checkBidgNo,
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.regParams,
                                            "cstBidgMgrNo",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "border_left_none" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.rtnCgoYn,
                                            expression: "regParams.rtnCgoYn",
                                          },
                                        ],
                                        attrs: {
                                          id: "chk4",
                                          type: "checkbox",
                                          "true-value": "Y",
                                          "false-value": "N",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.regParams.rtnCgoYn
                                          )
                                            ? _vm._i(
                                                _vm.regParams.rtnCgoYn,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.regParams.rtnCgoYn,
                                                "Y"
                                              ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkRtnCgoYn()
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.regParams.rtnCgoYn,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "Y" : "N"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "rtnCgoYn",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "rtnCgoYn",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.regParams,
                                                "rtnCgoYn",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("label", { attrs: { for: "chk4" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.160"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "border_left_none" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.impBlNo,
                                            expression: "regParams.impBlNo",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.regParams.rtnCgoYn !== "Y",
                                          placeholder:
                                            this.$t("msg.CSBK100.123"),
                                        },
                                        domProps: {
                                          value: _vm.regParams.impBlNo,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.regParams,
                                              "impBlNo",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("td", { attrs: { colspan: "2" } }),
                                ]),
                              ]
                            : [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "border_left_none" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.rtnCgoYn,
                                            expression: "regParams.rtnCgoYn",
                                          },
                                        ],
                                        attrs: {
                                          id: "chk4",
                                          type: "checkbox",
                                          "true-value": "Y",
                                          "false-value": "N",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.regParams.rtnCgoYn
                                          )
                                            ? _vm._i(
                                                _vm.regParams.rtnCgoYn,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.regParams.rtnCgoYn,
                                                "Y"
                                              ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkRtnCgoYn()
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.regParams.rtnCgoYn,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "Y" : "N"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "rtnCgoYn",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.regParams,
                                                    "rtnCgoYn",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.regParams,
                                                "rtnCgoYn",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("label", { attrs: { for: "chk4" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.160"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "border_left_none" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.impBlNo,
                                            expression: "regParams.impBlNo",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            _vm.regParams.rtnCgoYn !== "Y",
                                          placeholder:
                                            this.$t("msg.CSBK100.123"),
                                        },
                                        domProps: {
                                          value: _vm.regParams.impBlNo,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.regParams,
                                              "impBlNo",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("th", [
                                    _vm._v(" Bidding No."),
                                    _c("span", { staticClass: "txt" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.$t("msg.CSBK100.062")) +
                                          ")"
                                      ),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.cstBidgMgrNo,
                                          expression: "regParams.cstBidgMgrNo",
                                        },
                                      ],
                                      attrs: { type: "text", maxlength: "10" },
                                      domProps: {
                                        value: _vm.regParams.cstBidgMgrNo,
                                      },
                                      on: {
                                        change: _vm.checkBidgNo,
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.regParams,
                                            "cstBidgMgrNo",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ],
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Etc File Upload"),
                            ]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("div", { staticClass: "filebox" }, [
                                _c(
                                  "a",
                                  {
                                    class:
                                      _vm.etcDeleteAllYn === "N"
                                        ? "button sm gray file"
                                        : "button sm blue",
                                    on: {
                                      click: function ($event) {
                                        return _vm.etcDocUploadPopup()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.205")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _vm.regParams.polPortCd == "PGU"
                      ? _c("table", { staticClass: "tbl_row mt10" }, [
                          _vm._m(7),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Forwading Code")]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.fwdEdiCd,
                                      expression: "regParams.fwdEdiCd",
                                    },
                                  ],
                                  staticClass: "col_5",
                                  attrs: {
                                    id: "fwdEdiCd",
                                    type: "text",
                                    maxlength: "4",
                                  },
                                  domProps: { value: _vm.regParams.fwdEdiCd },
                                  on: {
                                    keyup: function ($event) {
                                      return _vm.checkValidationCargoInfo(
                                        "fwdEdiCd"
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "fwdEdiCd",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.regParams.polShtmCd === "01" &&
              _vm.lang === "KOR" &&
              _vm.cstCatCd !== "Y" &&
              _vm.regParams.mtyPkupUsagYn !== "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.042")) + " "),
                    ]),
                    _c("table", { staticClass: "tbl_col tbl_owtr_info" }, [
                      _vm._m(8),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.043"))),
                          ]),
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.044"))),
                          ]),
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.066"))),
                          ]),
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.067"))),
                          ]),
                          _c("th", { staticClass: "text_center" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("msg.CSBK100.046")) +
                                "/" +
                                _vm._s(_vm.$t("msg.CSBK100.161")) +
                                "/email "
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "border_left_none full" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.owtrYn,
                                    expression: "regParams.owtrYn",
                                  },
                                ],
                                on: {
                                  click: _vm.checkOwtrYn,
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "owtrYn",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.changeOwtrYn,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "N" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.047"))),
                                ]),
                                _c("option", { attrs: { value: "Y" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.048"))),
                                ]),
                              ]
                            ),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "search_btn text_left" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.tfcoCstNm,
                                    expression: "regParams.tfcoCstNm",
                                  },
                                ],
                                staticClass: "inp_responsive_space7",
                                attrs: {
                                  id: "tfcoCstNm",
                                  type: "text",
                                  readonly: "",
                                },
                                domProps: { value: _vm.regParams.tfcoCstNm },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "tfcoCstNm",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm.regParams.owtrYn === "Y"
                                ? _c("button", {
                                    staticClass: "search_btn01 ml2",
                                    attrs: { id: "btn_tfcoCstNmSrch" },
                                    on: { click: _vm.transCompanySearch },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c("td", [
                            _vm.regParams.owtrYn === "N"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.zip1,
                                        expression: "regParams.zip1",
                                      },
                                    ],
                                    staticClass: "inp_responsive_space1",
                                    attrs: { id: "zip1" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "zip1",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.changeZip1,
                                      ],
                                      click: _vm.checkZip1,
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }),
                                    _vm._l(_vm.zip1List, function (zvo) {
                                      return _c(
                                        "option",
                                        {
                                          key: zvo.region,
                                          domProps: { value: zvo.region },
                                        },
                                        [_vm._v(" " + _vm._s(zvo.region) + " ")]
                                      )
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.regParams.owtrYn === "N"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.zip2,
                                      expression: "regParams.zip2",
                                    },
                                  ],
                                  staticClass: "inp_responsive_space1",
                                  attrs: { type: "text", maxlength: "30" },
                                  domProps: { value: _vm.regParams.zip2 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "zip2",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c("td", { staticClass: "position_relative" }, [
                            _vm.regParams.owtrYn === "N"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.cboFactory,
                                        expression: "cboFactory",
                                      },
                                    ],
                                    staticClass: "inp_responsive_space1",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.cboFactory = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        _vm.changeFactoryInfo,
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(
                                        _vm._s(this.$t("msg.CSBK100.050"))
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.factoryInfoList,
                                      function (vo, i) {
                                        return _c(
                                          "option",
                                          {
                                            key: i,
                                            domProps: {
                                              value:
                                                vo.facPicNm +
                                                "$" +
                                                vo.facCtfcNo +
                                                "$" +
                                                vo.facNm,
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(vo.facNm) + " ")]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              { staticClass: "position_relative ml2" },
                              [
                                _vm.regParams.owtrYn === "N"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.facNm,
                                          expression: "regParams.facNm",
                                        },
                                      ],
                                      staticClass: "inp_responsive_space1",
                                      attrs: { type: "text", maxlength: "20" },
                                      domProps: { value: _vm.regParams.facNm },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.regParams,
                                            "facNm",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "text_left" }, [
                            _c("div", { staticClass: "d_inline_block" }, [
                              _vm.regParams.owtrYn === "N"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.facPicNm,
                                        expression: "regParams.facPicNm",
                                      },
                                    ],
                                    staticClass: "wid50",
                                    attrs: {
                                      id: "facPicNm",
                                      type: "text",
                                      placeholder: _vm.$t("msg.CSBK100.126"),
                                      maxlength: "20",
                                    },
                                    domProps: { value: _vm.regParams.facPicNm },
                                    on: {
                                      keyup: function ($event) {
                                        return _vm.checkValidationTrans(
                                          "facPicNm"
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "facPicNm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.regParams.owtrYn === "N"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.facCtfcNo,
                                        expression: "regParams.facCtfcNo",
                                      },
                                    ],
                                    staticClass: "wid130",
                                    attrs: {
                                      id: "facCtfcNo",
                                      type: "text",
                                      placeholder: _vm.$t("msg.CSBK100.161"),
                                      maxlength: "20",
                                    },
                                    domProps: {
                                      value: _vm.regParams.facCtfcNo,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        return _vm.checkValidationTrans(
                                          "facCtfcNo"
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "facCtfcNo",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.regParams.owtrYn === "N"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.facPicEmlAddr,
                                        expression: "regParams.facPicEmlAddr",
                                      },
                                    ],
                                    staticClass: "wid130 ml2",
                                    attrs: {
                                      id: "facPicEmlAddr",
                                      type: "text",
                                      placeholder: "email",
                                      maxlength: "20",
                                    },
                                    domProps: {
                                      value: _vm.regParams.facPicEmlAddr,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "facPicEmlAddr",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text_left",
                              attrs: { colspan: "2" },
                            },
                            [
                              _c("span", { staticClass: "span_rmk" }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.162"))),
                              ]),
                            ]
                          ),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c("div", { staticClass: "position_relative" }, [
                              _c(
                                "div",
                                { staticStyle: { "margin-right": "150px" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.dorOdrRmk,
                                        expression: "regParams.dorOdrRmk",
                                      },
                                    ],
                                    attrs: {
                                      id: "docmRmk",
                                      type: "text",
                                      maxlength: "100",
                                      placeholder: this.$t("msg.CSBK100.122"),
                                      readonly:
                                        "IN|PK|BD|RU|IR|LK|AE".indexOf(
                                          _vm.regParams.podCtrCd
                                        ) > -1
                                          ? true
                                          : false,
                                    },
                                    domProps: {
                                      value: _vm.regParams.dorOdrRmk,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "dorOdrRmk",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.memberDetail.userCtrCd === "PH" ||
              _vm.regParams.polCtrCd === "PH"
                ? _c(
                    "a",
                    {
                      staticClass: "button sm mt5",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.templateDownloadForPH.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX050G020.021")) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "flex_box mt20" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.040")) + " "),
                ]),
                _c("span", { staticClass: "ml_auto" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.usrCtrCd === "JP" && _vm.dgCargoYn === "Y",
                          expression: "usrCtrCd === 'JP' && dgCargoYn === 'Y'",
                        },
                      ],
                      staticClass: "button sm",
                      attrs: {
                        href: "/pdf/eKMTC_DG_Application-JP.pdf",
                        download: "",
                      },
                    },
                    [_vm._v("How to input DG application ")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      class: { disabled: _vm.isDetCombined },
                      on: { click: _vm.demDetInfoPop },
                    },
                    [
                      _c("span", { staticClass: "btn_icon calculator" }),
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.163")) + " "),
                    ]
                  ),
                ]),
              ]),
              _c(
                "table",
                { staticClass: "tbl_col" },
                [
                  _vm._m(9),
                  _c("thead", { attrs: { id: "containerHeader" } }, [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "4" } }, [
                        _vm._v("CONTAINER"),
                      ]),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.068"))),
                      ]),
                      _vm.regParams.polShtmCd !== "02"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.069")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.regParams.polShtmCd !== "02"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.070")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.regParams.polShtmCd === "02"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.073")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.regParams.polShtmCd === "02" && _vm.lang === "KOR"
                        ? _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.164"))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.164_1")) + " "),
                          ])
                        : _vm._e(),
                      _vm.regParams.polShtmCd === "02" && _vm.lang !== "KOR"
                        ? _c("th", { attrs: { rowspan: "2" } })
                        : _vm._e(),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            on: { click: _vm.addContainer },
                          },
                          [_vm._v(" plus ")]
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Size")]),
                      _c("th", [
                        _vm._v(" Type "),
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon help ml5",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.openPopup("container-pop")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "offscreen" }, [
                              _vm._v("도움말"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("th", [_vm._v("Q'ty")]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.071")))]),
                    ]),
                  ]),
                  _vm._l(_vm._containerList, function (vo, idx) {
                    return _c("tbody", { key: "tr_container_" + idx }, [
                      _c("tr", [
                        _c("td", [
                          _c(
                            "select",
                            {
                              attrs: { id: "cntrSzCd_" + idx },
                              domProps: { value: vo.cntrSzCd },
                              on: {
                                change: function ($event) {
                                  return _vm.changeCntrSz(vo, idx, $event)
                                },
                              },
                            },
                            _vm._l(_vm.cntSzList, function (option) {
                              return _c(
                                "option",
                                {
                                  key: option.cd,
                                  domProps: { value: option.cd },
                                },
                                [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              attrs: { id: "cntrTypCd_" + idx },
                              domProps: { value: vo.cntrTypCd },
                              on: {
                                change: function ($event) {
                                  return _vm.changeCntrTyp(vo, idx, $event)
                                },
                                click: _vm.checkCntrTyp,
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("---------------------"),
                              ]),
                              _vm._l(vo.subCntrTypList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                value: vo.cntrQty,
                                id: "cntrQty_" + idx,
                                maxlength: "3",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkValidationCntr(
                                    "cntrQty",
                                    idx,
                                    "KEYUP",
                                    ...arguments
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("input", {
                            attrs: {
                              id: "shprVanYn_" + idx,
                              type: "checkbox",
                              disabled: _vm.regParams.polShtmCd === "02",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: vo.shprVanYn === "Y" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.checkShprVanYn($event, vo, idx)
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "shprVanYn_" + idx } }, [
                            _c("span", { staticClass: "offscreen" }, [
                              _vm._v("화주 컨테이너 체크"),
                            ]),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", [
                            _c(
                              "select",
                              {
                                class: _vm.isRfDg(vo) ? "col_85" : "",
                                attrs: { id: "cgoTypCd_" + idx },
                                domProps: { value: vo.cgoTypCd },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeCgoTyp(vo, idx, $event)
                                  },
                                },
                              },
                              [
                                _vm.isDefaultCgoValue(vo)
                                  ? _c("option", { attrs: { value: "" } }, [
                                      _vm._v(
                                        " --------------------------------------- "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._l(vo.subCgoTypList, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.cd,
                                      domProps: { value: option.cd },
                                    },
                                    [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm.isRfDg(vo)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_rf_entry float_right",
                                    on: {
                                      click: function ($event) {
                                        return _vm.rfDgRequestPop(idx)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBL200.258")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm.$ekmtcCommon.isNotEmpty(vo.apvNo)
                            ? _c("div", { staticClass: "danger_num" }, [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.165"))),
                                ]),
                                _c("div", { staticClass: "color_red" }, [
                                  _vm._v(" " + _vm._s(vo.apvNo) + " "),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "input_calendar" },
                            [
                              _vm.cstCatCd !== "Y"
                                ? _c("e-date-picker", {
                                    attrs: {
                                      value: vo.pcupReqDt,
                                      id: "pcupReqDt_" + idx,
                                      disabled:
                                        (vo.shprVanYn === "Y" &&
                                          _vm.regParams.owtrYn === "Y") ||
                                        _vm.usrCtrCd === "PH" ||
                                        _vm.pcupDtDisabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationCntr(
                                          "pcupReqDt",
                                          idx,
                                          "KEYUP",
                                          ...arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.lang !== "KOR" && _vm.cstCatCd !== "Y"
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.pcupReqTm,
                                          expression: "vo.pcupReqTm",
                                        },
                                      ],
                                      staticClass: "mt5 col_8",
                                      attrs: {
                                        id: "pcupReqTm_" + idx,
                                        disabled:
                                          vo.shprVanYn === "Y" &&
                                          _vm.regParams.owtrYn === "Y",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            vo,
                                            "pcupReqTm",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "09" } }, [
                                        _vm._v("09:00"),
                                      ]),
                                      _c("option", { attrs: { value: "10" } }, [
                                        _vm._v("10:00"),
                                      ]),
                                      _c("option", { attrs: { value: "11" } }, [
                                        _vm._v("11:00"),
                                      ]),
                                      _c("option", { attrs: { value: "12" } }, [
                                        _vm._v("12:00"),
                                      ]),
                                      _c("option", { attrs: { value: "13" } }, [
                                        _vm._v("13:00"),
                                      ]),
                                      _c("option", { attrs: { value: "14" } }, [
                                        _vm._v("14:00"),
                                      ]),
                                      _c("option", { attrs: { value: "15" } }, [
                                        _vm._v("15:00"),
                                      ]),
                                      _c("option", { attrs: { value: "16" } }, [
                                        _vm._v("16:00"),
                                      ]),
                                      _c("option", { attrs: { value: "17" } }, [
                                        _vm._v("17:00"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("td", [
                          _vm.cstCatCd !== "Y"
                            ? _c("div", [
                                _vm.lang !== "KOR" &&
                                _vm.regParams.polShtmCd === "01"
                                  ? _c("div", [
                                      _c("p", [
                                        _c("input", {
                                          attrs: {
                                            id: "pcupCyCd_" + idx,
                                            type: "text",
                                            placeholder:
                                              _vm.$t("msg.CSBK100.381"),
                                            readonly: "",
                                            disabled:
                                              vo.shprVanYn === "Y" &&
                                              _vm.regParams.owtrYn === "Y",
                                          },
                                          domProps: { value: vo.pickUpPlace },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getPickupPlace(idx)
                                            },
                                          },
                                        }),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.lang === "KOR" &&
                                _vm.regParams.polShtmCd === "01"
                                  ? _c(
                                      "div",
                                      { staticClass: "position_relative" },
                                      [
                                        _c("p", [
                                          _c("input", {
                                            attrs: {
                                              id: "pcupCyCd_" + idx,
                                              type: "text",
                                              placeholder:
                                                _vm.$t("msg.CSBK100.381"),
                                              readonly: "",
                                              disabled:
                                                vo.shprVanYn === "Y" &&
                                                _vm.regParams.owtrYn === "Y",
                                            },
                                            domProps: { value: vo.pickUpPlace },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getPickupPlace(idx)
                                              },
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "p",
                                          { staticStyle: { margin: "5px 0" } },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: vo.pickUpMan,
                                                  expression: "vo.pickUpMan",
                                                },
                                              ],
                                              attrs: {
                                                id: "pickUpMan_" + idx,
                                                type: "text",
                                                readonly: "",
                                                disabled:
                                                  vo.shprVanYn === "Y" &&
                                                  _vm.regParams.owtrYn === "Y",
                                                placeholder:
                                                  _vm.$t("msg.CSBK100.129"),
                                              },
                                              domProps: { value: vo.pickUpMan },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    vo,
                                                    "pickUpMan",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("p", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.pickUpTel,
                                                expression: "vo.pickUpTel",
                                              },
                                            ],
                                            attrs: {
                                              id: "pickUpTel_" + idx,
                                              type: "text",
                                              readonly: "",
                                              disabled:
                                                vo.shprVanYn === "Y" &&
                                                _vm.regParams.owtrYn === "Y",
                                              placeholder:
                                                _vm.$t("msg.CSBK100.161"),
                                            },
                                            domProps: { value: vo.pickUpTel },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  vo,
                                                  "pickUpTel",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.lang === "KOR" &&
                                _vm.regParams.polShtmCd !== "01" &&
                                idx === 0
                                  ? _c("div", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.regParams.cfsFixdTypCd,
                                              expression:
                                                "regParams.cfsFixdTypCd",
                                            },
                                          ],
                                          staticClass: "col_4",
                                          attrs: { id: "cfsFixdTypCd_" + idx },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.regParams,
                                                  "cfsFixdTypCd",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              _vm.changeCfsFixdType,
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "01" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.047")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "02" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.048")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "03" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("msg.CSBK100.166")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.regParams.cfsFixdEntrNm,
                                            expression:
                                              "regParams.cfsFixdEntrNm",
                                          },
                                        ],
                                        staticClass: "col_5",
                                        attrs: {
                                          id: "cfsFixdEntrNm_" + idx,
                                          type: "text",
                                          readonly:
                                            _vm.regParams.cfsFixdTypCd !== "02",
                                        },
                                        domProps: {
                                          value: _vm.regParams.cfsFixdEntrNm,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.checkValidationCntr(
                                              "cfsFixdEntrNm",
                                              idx,
                                              "KEYUP"
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.regParams,
                                              "cfsFixdEntrNm",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.cstCatCd === "Y"
                            ? _c("div", [
                                _c("p", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: vo.pickUpPlace,
                                        expression: "vo.pickUpPlace",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("msg.CSBK100.381"),
                                      readonly: "",
                                    },
                                    domProps: { value: vo.pickUpPlace },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPickupPlace(idx)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          vo,
                                          "pickUpPlace",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          (_vm.saveFlag === "EDIT" &&
                            _vm.regParams.mtyPkupUsagYn !== "Y") ||
                          _vm.$ekmtcCommon.isEmpty(vo.pcupReqDtOld)
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeContainer(idx)
                                    },
                                  },
                                },
                                [_vm._v(" minus ")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH"
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text_left btn_responsive",
                                attrs: { colspan: "2" },
                              },
                              [
                                _c("input", {
                                  attrs: {
                                    id: "chk12_" + idx,
                                    type: "checkbox",
                                    "true-value": "Y",
                                    "false-value": "N",
                                  },
                                  domProps: {
                                    checked: vo.chkNOR === "Y" ? true : false,
                                    value: vo.chkNOR,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetRfSetup($event, idx)
                                    },
                                    change: function ($event) {
                                      return _vm.setContainer(
                                        "chkNOR",
                                        $event.target.checked ? "Y" : "N",
                                        idx
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "chk12_" + idx } },
                                  [_vm._v("NOR(Non Operating Reefer)")]
                                ),
                                _vm._v("  "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "position_relative span_tooltip",
                                  },
                                  [
                                    _c("button", {
                                      staticClass: "tbl_icon help",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$ekmtcCommon.showTooltip(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tooltip_wrap short position_absolute",
                                      },
                                      [
                                        _c("div", { staticClass: "cont" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "close",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$ekmtcCommon.hideTooltip(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" close ")]
                                          ),
                                          _c(
                                            "ul",
                                            { staticClass: "bul_list_sm t2" },
                                            [
                                              _c("li", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("msg.CSBK100.143")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.025"))),
                            ]),
                            _c(
                              "td",
                              [
                                _c("e-input-number", {
                                  class: "col_4",
                                  attrs: {
                                    id: "rfSetupTmpr_" + idx,
                                    value: vo.rfSetupTmpr,
                                    "is-minus": true,
                                    disabled: vo.chkNOR === "Y" ? true : false,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkValidationRF(
                                        "rfSetupTmpr",
                                        idx,
                                        ...arguments
                                      )
                                    },
                                  },
                                }),
                                _vm.usrCtrCd !== "KR" && vo.chkNOR !== "Y"
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: vo.rfTmprUnitCd,
                                            expression: "vo.rfTmprUnitCd",
                                          },
                                        ],
                                        staticClass: "col_5",
                                        attrs: {
                                          disabled:
                                            vo.chkNOR === "Y" ? true : false,
                                          id: "rfTmprUnitCd_" + idx,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              vo,
                                              "rfTmprUnitCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "C" } },
                                          [_vm._v("℃")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "F" } },
                                          [_vm._v("℉")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.usrCtrCd === "KR" || vo.chkNOR === "Y"
                                  ? _c("span", { staticClass: "d_inline" }, [
                                      _vm._v("℃"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("VENT"),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  attrs: {
                                    id: "cntrRfStsCd_" + idx,
                                    disabled: vo.chkNOR === "Y" ? true : false,
                                  },
                                  domProps: { value: vo.cntrRfStsCd },
                                  on: {
                                    change: function ($event) {
                                      return _vm.checkValidationRF(
                                        "cntrRfStsCd",
                                        idx,
                                        $event.target.value
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.regParams.polCtrCd === "VN" &&
                                  _vm.cntrRfStsYn === "Y"
                                    ? _vm._l(
                                        _vm.cntrRfStsListVN,
                                        function (cvo) {
                                          return _c(
                                            "option",
                                            {
                                              key: cvo.cd,
                                              domProps: { value: cvo.cd },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(cvo.cdNm) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._l(_vm.cntrRfStsList, function (cvo) {
                                        return _c(
                                          "option",
                                          {
                                            key: cvo.cd,
                                            domProps: { value: cvo.cd },
                                          },
                                          [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                                        )
                                      }),
                                ],
                                2
                              ),
                            ]),
                            _c("th", { attrs: { scope: "row" } }, [
                              vo.iotCntrYn === "Y"
                                ? _c("span", [
                                    _vm._v(" IoT Container  "),
                                    _c("button", {
                                      staticClass: "tbl_icon help ml5",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPopup(
                                            "container-iot-pop"
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              vo.iotCntrYn === "Y" || vo.iotEqpmYn === "Y"
                                ? _c("input", {
                                    attrs: {
                                      id: "iotEqpmYn_" + idx,
                                      type: "checkbox",
                                      disabled:
                                        vo.iotCntrYn === "Y" ||
                                        vo.iotEqpmYn === "Y"
                                          ? false
                                          : true,
                                      "true-value": "Y",
                                      "false-value": "N",
                                    },
                                    domProps: {
                                      checked:
                                        vo.iotEqpmYn === "Y" ? true : false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setContainer(
                                          "iotEqpmYn",
                                          $event.target.checked ? "Y" : "N",
                                          idx
                                        )
                                      },
                                      click: function ($event) {
                                        return _vm.checkIotYn($event, vo, idx)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("label", {
                                attrs: { for: "iotEqpmYn_" + idx },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      (vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH") &&
                      ["SG"].includes(_vm.memberDetail.userCtrCd)
                        ? _c("tr", [
                            _c("th", [_vm._v("PRE-SET/COOL")]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.rfPreSetCd,
                                      expression: "vo.rfPreSetCd",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "rfPreSetCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("---------"),
                                  ]),
                                  _vm._l(_vm.preSetList, function (cvo) {
                                    return _c(
                                      "option",
                                      {
                                        key: cvo.cd,
                                        domProps: { value: cvo.cd },
                                      },
                                      [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _c("th", [_vm._v("Slot")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(Number(vo.pcupReqTm) > 11 ? "PM" : "AM")
                              ),
                            ]),
                            _c("td"),
                            _c("td"),
                            _c("td"),
                            _c("td"),
                          ])
                        : _vm._e(),
                      (vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH") &&
                      _vm.usrCtrCd === "KR"
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "6" } }, [
                              _c("div", { staticClass: "select_opt_area" }, [
                                _c("div", { staticClass: "desc_list" }, [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.CSBK100.168_1"))
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.CSBK100.168_2"))
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.CSBK100.168_3"))
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.CSBK100.168_4")) +
                                          " "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text_decoration",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            href: "/#/common/pds?messageIdSeq=CKR362&groupId=362",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("msg.CSBK100.168_5"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm.lang === "KOR"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openRfHpmgSite(
                                            vo.rfHmpgUrl
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("msg.CSBK100.167")))]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      vo.cgoTypCd !== "" &&
                      "01|02|03|06".indexOf(vo.cgoTypCd) > -1
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: { "text-align": "left" },
                                attrs: { colspan: "8" },
                              },
                              [
                                vo.cgoTypCd === "01"
                                  ? _c("booking-table-dg", {
                                      ref: "dg",
                                      refInFor: true,
                                      attrs: {
                                        index: idx,
                                        "parent-info": vo,
                                        "parent-dg-list": vo.containerDGList,
                                        "cas-no-port-yn": _vm.casNoPortYn,
                                        "tel-ctr-no": _vm.usrInfo.telCtrNo,
                                        "disabled-yn": _vm.disabledYn,
                                        "unno-disabled-yn": _vm.unnoDisabledYn,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                        "bkg-sts-cd": _vm.regParams.bkgStsCd,
                                        "bkg-vsl-doc-yn": _vm.bkgVslDocYn,
                                        "status-class": _vm.statusClass,
                                      },
                                      on: { vanningChk: _vm.vanningChk },
                                    })
                                  : _vm._e(),
                                vo.cgoTypCd === "02" ||
                                vo.cgoTypCd === "03" ||
                                (vo.cgoTypCd === "03" &&
                                  vo.cntrTypCd === "HC" &&
                                  vo.cntrSzCd === "45")
                                  ? _c("booking-table-oog", {
                                      ref: "oog",
                                      refInFor: true,
                                      attrs: {
                                        index: idx,
                                        "cgo-typ-cd": vo.cgoTypCd,
                                        "parent-info": vo,
                                        "kmd-vsl-check": _vm.kmdVslCheck,
                                        "empty-flag": _vm.regParams.emptyFlag,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                        "cntr-typ-cd": vo.cntrTypCd,
                                        "sts-and-app-no-yn": _vm.stsAndAppNoYn,
                                        "grs-wt-ton": _vm.grsWtTon,
                                      },
                                    })
                                  : _vm._e(),
                                vo.cgoTypCd === "06"
                                  ? _c("booking-table-flexi", {
                                      ref: "flexi",
                                      refInFor: true,
                                      attrs: {
                                        index: idx,
                                        "parent-info": vo,
                                        lang: _vm.lang,
                                        "save-flag": _vm.saveFlag,
                                        "loi-down-yn": _vm.loiDownYn,
                                      },
                                      on: { fbCatInfo: _vm.callFbCatInfo },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      vo.cgoTypCd === "01"
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "8" } }, [
                              _c("div", { staticClass: "select_opt_area" }, [
                                _c("div", { staticClass: "desc_list" }, [
                                  _vm.lang === "KOR"
                                    ? _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            " UNNO 미부착 또는 수기(Handwritten) 시, 기항지에서 큰 액수의 벌금이 부과될 수 있으므로 선적 과정에서 발견될 경우, 통보없이 선적 거절될 수 있습니다. (FAQ 참조) "
                                          ),
                                        ]),
                                        _c("li", { staticClass: "color_red" }, [
                                          _vm._v(
                                            " CAS No. 제약이 있는 항만으로 수출입할 경우 MSDS에 기재된 모든 CAS No.를 누락없이 입력하셔야 하며 부정확한 정보 입력으로 인하여 발생하는 모든 책임은 신고자(송하인)에게 있음을 알려 드립니다. "
                                          ),
                                        ]),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false",
                                              },
                                            ],
                                            staticClass: "color_red",
                                          },
                                          [
                                            _vm._v(
                                              " MSDS상 Secret CAS No. (MSDS상 CAS No.미공개로 표시됨)가 있는 경우, 별도로 확인하여 알려주시거나 제공이 불가한 경우, 책임이 신고자(송하인)에게 있음을 승인하는 LOI 제출 바랍니다. * 당사 공지사항 참조 "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.lang !== "KOR"
                                    ? _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            " In case of missing UNNO or handwritten UNNO on the DG container can result in large amount of penalty at calling ports. If it is found before loading, it could be rejected without pre-notice. "
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(_vm.$t("msg.CSBK100.094"))
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(_vm.$t("msg.CSBK100.095"))
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(_vm.$t("msg.CSBK100.096"))
                                          ),
                                        ]),
                                        _vm._m(10, true),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false",
                                              },
                                            ],
                                            staticClass: "color_red",
                                          },
                                          [
                                            _vm._v(
                                              " If there are 'secret CAS numbers' which are unwritten CAS numbers, Please let us know directly. If It isn't possible to provide it, Please submit an LOI, confirming that all of the responsibilities rests with the shipper (Reporter). "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
              _vm.usrCtrCd === "TH"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("table", { staticClass: "tbl_col" }, [
                      _vm._m(11),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Return Date")]),
                          _c(
                            "td",
                            { staticClass: "text_left" },
                            [
                              _c("e-date-picker", {
                                model: {
                                  value: _vm.regParams.returnDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "returnDate", $$v)
                                  },
                                  expression: "regParams.returnDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.vanningYn === "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v("Charcoal Info."),
                    ]),
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(12),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("B/L Shipper"),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.chclActShprCstNo,
                                    expression: "regParams.chclActShprCstNo",
                                  },
                                ],
                                attrs: { id: "chclActShprCstNo" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "chclActShprCstNo",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.checkValidationVanningInfo(
                                        "chclActShprCstNo"
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select"),
                                ]),
                                _vm._l(_vm.chclActList, function (cvo) {
                                  return _c(
                                    "option",
                                    {
                                      key: cvo.actShprCstNo,
                                      domProps: { value: cvo.actShprCstNo },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(cvo.actShprCstNm) + " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Vanning Surveyor."),
                          ]),
                          _c("td", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.vaniSurvCstNo,
                                    expression: "regParams.vaniSurvCstNo",
                                  },
                                ],
                                attrs: {
                                  id: "vaniSurvCstNo",
                                  disabled: _vm.vanningSurvYn === "N",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "vaniSurvCstNo",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.checkValidationVanningInfo(
                                        "vaniSurvCstNo"
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select"),
                                ]),
                                _vm._l(_vm.chclActList, function (cvo) {
                                  return _c(
                                    "option",
                                    {
                                      key: cvo.vaniSurvCstNo,
                                      domProps: { value: cvo.vaniSurvCstNo },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(cvo.vaniSurvCstNm) + " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.msdsCheck === "Y" && _vm.regParams.podPortCd === "SHA"
                ? _c("div", { staticClass: "desc_list mt20" }, [_vm._m(13)])
                : _vm._e(),
              _vm.msdsCheck === "Y" && _vm.regParams.podPortCd !== "SHA"
                ? _c("div", { staticClass: "desc_list mt20" }, [_vm._m(14)])
                : _vm._e(),
              _vm.consigneeYn === "Y"
                ? _c("div", { staticClass: "mt20" }, [
                    _c("h2", { staticClass: "content_title text_left" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.169"))),
                    ]),
                    _c("table", { staticClass: "tbl_col t2" }, [
                      _vm._m(15),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.129"))),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.148"))),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.170"))),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.$t("msg.CSBK100.162")) + ")"
                              ),
                            ]),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(" email"),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.$t("msg.CSBK100.162")) + ")"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicNm,
                                  expression: "regParams.cnePicNm",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "cnePicNm",
                                maxlength: "100",
                              },
                              domProps: { value: _vm.regParams.cnePicNm },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "cnePicNm",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.inputConsignee(
                                      "cnePicNm",
                                      "ESSENTIAL"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicTelNo,
                                  expression: "regParams.cnePicTelNo",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "cnePicTelNo",
                                maxlength: "30",
                              },
                              domProps: { value: _vm.regParams.cnePicTelNo },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "cnePicTelNo",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.inputConsignee(
                                      "cnePicTelNo",
                                      "ESSENTIAL"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-input-number", {
                                attrs: {
                                  id: "cnePicTelNo",
                                  maxlength: "30",
                                  "is-phone": true,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.inputConsignee(
                                      "cnePicFaxNo",
                                      "ESSENTIAL"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.regParams.cnePicFaxNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "cnePicFaxNo", $$v)
                                  },
                                  expression: "regParams.cnePicFaxNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.cnePicEmlAddr,
                                  expression: "regParams.cnePicEmlAddr",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "cnePicEmlAddr",
                                maxlength: "100",
                              },
                              domProps: { value: _vm.regParams.cnePicEmlAddr },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams,
                                    "cnePicEmlAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.mtyPkupUsagYn !== "Y" && _vm.usrCtrCd !== "VN"
                ? _c("close-info", {
                    attrs: {
                      "parent-info": _vm.bkgCloseInfo,
                      "vessel-info": _vm.bkgCloseVslInfo,
                    },
                  })
                : _vm._e(),
              _vm.lang === "KOR"
                ? _c("div", { staticClass: "desc_list" }, [
                    _c("ul", [
                      _c("li", { staticClass: "color_blue" }, [
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.175"))),
                        _c("br"),
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.176")) + " "),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              (_vm.regParams.podCtrCd === "IR" ||
                _vm.regParams.podCtrCd === "RU") &&
              _vm.lang === "KOR"
                ? _c(
                    "div",
                    {
                      staticClass: "desc_list",
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "-15px",
                      },
                    },
                    [_vm._m(16)]
                  )
                : _vm._e(),
              _vm.regParams.podCtrCd === "IR" || _vm.regParams.podCtrCd === "RU"
                ? _c("table", { staticClass: "tbl_row mt20" }, [
                    _vm._m(17),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                          _vm._v("B/L SHIPPER"),
                        ]),
                        _c("th", { attrs: { width: "150" } }, [_vm._v("NAME")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.actShprCstNm,
                                expression:
                                  "regParams.iranBookingInfo.actShprCstNm",
                              },
                            ],
                            attrs: {
                              id: "ir_actShprCstNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.actShprCstNm,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.iranInputCheck("actShprCstNm")
                              },
                              keyup: function ($event) {
                                return _vm.checkIranValidation("actShprCstNm")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "actShprCstNm",
                                    $event.target.value
                                  )
                                },
                                _vm.changeActShprCstNm,
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(
                                  _vm.regParams.iranBookingInfo.actShprCstNmC
                                ) &&
                                _vm.regParams.iranBookingInfo.actShprCstNmC !==
                                  "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_actShprCstNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.115")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                            attrs: { scope: "row" },
                          },
                          [_vm._v("ADDRESS")]
                        ),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.actShprAddr,
                                expression:
                                  "regParams.iranBookingInfo.actShprAddr",
                              },
                            ],
                            attrs: {
                              id: "ir_actShprAddr",
                              type: "text",
                              maxlength: "300",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.actShprAddr,
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkIranValidation("actShprAddr")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "actShprAddr",
                                    $event.target.value
                                  )
                                },
                                _vm.changeActShprAddr,
                              ],
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                          _vm._v("B/L CONSIGNEE"),
                        ]),
                        _c("th", { attrs: { scope: "row" } }, [_vm._v("NAME")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.cneCstNm,
                                expression:
                                  "regParams.iranBookingInfo.cneCstNm",
                              },
                            ],
                            attrs: {
                              id: "ir_cneCstNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.cneCstNm,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.iranInputCheck("cneCstNm")
                              },
                              keyup: function ($event) {
                                return _vm.checkIranValidation("cneCstNm")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "cneCstNm",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    _vm.regParams.iranBookingInfo,
                                    "cneCstNm"
                                  )
                                },
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(
                                  _vm.regParams.iranBookingInfo.cneCstNmC
                                ) &&
                                _vm.regParams.iranBookingInfo.cneCstNmC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_cneCstNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.115")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                            attrs: { scope: "row" },
                          },
                          [_vm._v("ADDRESS")]
                        ),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.cneCstAddr,
                                expression:
                                  "regParams.iranBookingInfo.cneCstAddr",
                              },
                            ],
                            attrs: {
                              id: "ir_cneCstAddr",
                              type: "text",
                              maxlength: "300",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.cneCstAddr,
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkIranValidation("cneCstAddr")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "cneCstAddr",
                                    $event.target.value
                                  )
                                },
                                _vm.changCneCstAddr,
                              ],
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { scope: "row", rowspan: "2" } }, [
                          _vm._v("B/L NOTIFY"),
                        ]),
                        _c("th", { attrs: { scope: "row" } }, [_vm._v("NAME")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.ntifCstNm,
                                expression:
                                  "regParams.iranBookingInfo.ntifCstNm",
                              },
                            ],
                            attrs: {
                              id: "ir_ntifCstNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.ntifCstNm,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.iranInputCheck("ntifCstNm")
                              },
                              keyup: function ($event) {
                                return _vm.checkIranValidation("ntifCstNm")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "ntifCstNm",
                                    $event.target.value
                                  )
                                },
                                _vm.changNtifCstNm,
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(
                                  _vm.regParams.iranBookingInfo.ntifCstNmC
                                ) &&
                                _vm.regParams.iranBookingInfo.ntifCstNmC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_ntifCstNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.115")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                            attrs: { scope: "row" },
                          },
                          [_vm._v("ADDRESS")]
                        ),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.ntifCstAddr,
                                expression:
                                  "regParams.iranBookingInfo.ntifCstAddr",
                              },
                            ],
                            attrs: {
                              id: "ir_ntifCstAddr",
                              type: "text",
                              maxlength: "300",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.ntifCstAddr,
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkIranValidation("ntifCstAddr")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "ntifCstAddr",
                                    $event.target.value
                                  )
                                },
                                _vm.changNtifCstAddr,
                              ],
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("END USER"),
                        ]),
                        _c("td", [
                          _c("div", {}, [
                            _c(
                              "table",
                              { staticClass: "tbl_col" },
                              [
                                _vm._m(18),
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { attrs: { rowspan: "2" } }, [
                                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("NAME")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("ADDRESS")]),
                                    _vm._v(" "),
                                    _c("th", { attrs: { rowspan: "2" } }),
                                  ]),
                                  _vm._m(19),
                                ]),
                                _vm._l(_vm.endUserList, function (vo, idx) {
                                  return _c(
                                    "tbody",
                                    { key: "trEndUser_" + idx },
                                    [
                                      _c("tr", [
                                        _c("td", { attrs: { rowspan: "2" } }, [
                                          _vm._v(_vm._s(idx + 1)),
                                        ]),
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.endUsrNm,
                                                expression: "vo.endUsrNm",
                                              },
                                            ],
                                            attrs: {
                                              id: "ir_endUsrNm_" + idx,
                                              maxlength: "100",
                                              type: "text",
                                              placeholder: "NAME",
                                            },
                                            domProps: { value: vo.endUsrNm },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.iranInputCheck(
                                                  "endUsrNm",
                                                  idx
                                                )
                                              },
                                              keyup: function ($event) {
                                                return _vm.checkIranValidation(
                                                  "endUsrNm",
                                                  idx
                                                )
                                              },
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    vo,
                                                    "endUsrNm",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.changeOnlyUpperEng(
                                                    $event,
                                                    _vm.regParams
                                                      .iranBookingInfo,
                                                    "endUsrNm"
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              class:
                                                _vm.regParams.iranBookingInfo
                                                  .endUsrNmCList &&
                                                _vm.$ekmtcCommon.isNotEmpty(
                                                  _vm.regParams.iranBookingInfo
                                                    .endUsrNmCList[idx]
                                                ) &&
                                                _vm.regParams.iranBookingInfo
                                                  .endUsrNmCList[idx] !== "N"
                                                  ? ""
                                                  : "d_none",
                                              attrs: {
                                                id: "ir_endUsrNmC_" + idx,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("msg.CSBK100.115")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.endUsrAddr,
                                                expression: "vo.endUsrAddr",
                                              },
                                            ],
                                            attrs: {
                                              id: "ir_endUsrAddr_" + idx,
                                              maxlength: "300",
                                              type: "text",
                                              placeholder: "ADDRESS",
                                            },
                                            domProps: { value: vo.endUsrAddr },
                                            on: {
                                              keyup: function ($event) {
                                                return _vm.checkIranValidation(
                                                  "endUsrAddr",
                                                  idx
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  vo,
                                                  "endUsrAddr",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _c("td", { attrs: { rowspan: "2" } }, [
                                          idx === 0
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "tbl_icon plus",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addEndUser()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" plus ")]
                                              )
                                            : _vm._e(),
                                          idx !== 0
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "tbl_icon minus",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeEndUser(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" minus ")]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "border-left":
                                                "1px solid #e1e3eb",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: vo.endUsrTelNo,
                                                  expression: "vo.endUsrTelNo",
                                                },
                                              ],
                                              attrs: {
                                                id: "ir_endUsrTelNo_" + idx,
                                                maxlength: "25",
                                                type: "text",
                                                placeholder: "TEL",
                                              },
                                              domProps: {
                                                value: vo.endUsrTelNo,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      vo,
                                                      "endUsrTelNo",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.changeOnlyUpperEng(
                                                      $event,
                                                      _vm.regParams
                                                        .iranBookingInfo,
                                                      "endUsrTelNo"
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.endUsrEmlAddr,
                                                expression: "vo.endUsrEmlAddr",
                                              },
                                            ],
                                            attrs: {
                                              id: "ir_endUsrEmlAddr_" + idx,
                                              maxlength: "100",
                                              type: "text",
                                              placeholder: "EMAIL",
                                            },
                                            domProps: {
                                              value: vo.endUsrEmlAddr,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    vo,
                                                    "endUsrEmlAddr",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.changeOnlyUpperEng(
                                                    $event,
                                                    _vm.regParams
                                                      .iranBookingInfo,
                                                    "endUsrEmlAddr"
                                                  )
                                                },
                                              ],
                                              blur: function ($event) {
                                                return _vm.checkValidationEmail(
                                                  `ir_endUsrEmlAddr_${idx}`
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr"),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("NAME OF COMMODITY"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.cmdtNm,
                                expression: "regParams.iranBookingInfo.cmdtNm",
                              },
                            ],
                            attrs: {
                              id: "ir_cmdtNm",
                              type: "text",
                              maxlength: "100",
                            },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.cmdtNm,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.iranInputCheck("cmdtNm")
                              },
                              keyup: function ($event) {
                                return _vm.checkIranValidation("cmdtNm")
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.regParams.iranBookingInfo,
                                    "cmdtNm",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    _vm.regParams.iranBookingInfo,
                                    "cmdtNm"
                                  )
                                },
                              ],
                            },
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(_vm.cmdtNmC) &&
                                _vm.cmdtNmC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_cmdtNmC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.116")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("HS CODE"),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c(
                              "span",
                              { staticClass: "dv col_5" },
                              [
                                _c("e-input-number", {
                                  attrs: {
                                    id: "ir_hsCd",
                                    "is-phone": true,
                                    maxlength: "10",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.iranInputCheck("hsCd")
                                    },
                                    input: function ($event) {
                                      return _vm.checkIranValidation("hsCd")
                                    },
                                  },
                                  model: {
                                    value: _vm.regParams.iranBookingInfo.hsCd,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.regParams.iranBookingInfo,
                                        "hsCd",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "regParams.iranBookingInfo.hsCd",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "dv" }, [
                              _vm.saveFlag === "EDIT" &&
                              _vm.$ekmtcCommon.isNotEmpty(_vm.regParams.bkgNo)
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon plus",
                                      attrs: { type: "button" },
                                      on: { click: _vm.fnIranHsCdMulti },
                                    },
                                    [_vm._v(" plus ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "span",
                            {
                              class:
                                _vm.$ekmtcCommon.isNotEmpty(_vm.hsCdC) &&
                                _vm.hsCdC !== "N"
                                  ? ""
                                  : "d_none",
                              attrs: { id: "ir_hsCdC" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("msg.CSBK100.117")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("PURPOSE OF USE"),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.regParams.iranBookingInfo.useDestCont,
                                expression:
                                  "regParams.iranBookingInfo.useDestCont",
                              },
                            ],
                            attrs: { id: "ir_useDestCont", type: "text" },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.useDestCont,
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.checkIranValidation("useDestCont")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams.iranBookingInfo,
                                  "useDestCont",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("SHIPPER REMARK"),
                        ]),
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.iranBookingInfo.reqCont,
                                expression: "regParams.iranBookingInfo.reqCont",
                              },
                            ],
                            attrs: { id: "reqCont" },
                            domProps: {
                              value: _vm.regParams.iranBookingInfo.reqCont,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.regParams.iranBookingInfo,
                                  "reqCont",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                          _vm._v("KMTC REMARK"),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.regParams.iranBookingInfo.apvCont) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.cstNo === "PKE005" || _vm.lang !== "KOR"
                ? _c("div", { staticClass: "mt20 div_req_area" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v("Request"),
                    ]),
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(20),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("CY(Depot) Remark")]),
                          _c("td", [
                            _c("div", { staticClass: "position_relative" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.dorOdrRmk,
                                    expression: "regParams.dorOdrRmk",
                                  },
                                ],
                                attrs: {
                                  id: "dorOdrRmk",
                                  readonly:
                                    "IN|PK|BD|RU|IR|LK|AE".indexOf(
                                      _vm.regParams.podCtrCd
                                    ) > -1
                                      ? true
                                      : false,
                                },
                                domProps: { value: _vm.regParams.dorOdrRmk },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkValidationRemark(
                                      $event,
                                      2000
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "dorOdrRmk",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(21),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Document Remark")]),
                          _c("td", [
                            _vm.usrCtrCd !== "TH"
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.returnDate,
                                      expression: "regParams.returnDate",
                                    },
                                  ],
                                  domProps: { value: _vm.regParams.returnDate },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "returnDate",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.usrCtrCd === "TH"
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.returnDateTH,
                                      expression: "returnDateTH",
                                    },
                                  ],
                                  attrs: { readonly: "" },
                                  domProps: { value: _vm.returnDateTH },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.returnDateTH = $event.target.value
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Operation Remark")]),
                          _c("td", [
                            _c("div", { staticClass: "position_relative" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.oprRmk,
                                    expression: "regParams.oprRmk",
                                  },
                                ],
                                attrs: { id: "oprRmk" },
                                domProps: { value: _vm.regParams.oprRmk },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkValidationRemark(
                                      $event,
                                      2000
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.regParams,
                                      "oprRmk",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._m(22),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.saveFlag === "ADD"
            ? _c("div", [
                _c("div", { staticClass: "mt10 flex_box" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button lg mr5",
                      on: {
                        click: function ($event) {
                          return _vm.goList()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ADMN030G020.017")))]
                  ),
                  ["SG"].includes(_vm.memberDetail.userCtrCd)
                    ? _c(
                        "a",
                        {
                          staticClass: "button lg mr5",
                          attrs: {
                            href: "/template/LOI for Pre-set Reefer.docx",
                            download: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.553")) + " ")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "ml_auto text_right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button blue lg mr5",
                        on: {
                          click: function ($event) {
                            return _vm.saveBooking()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "button gray lg",
                        on: {
                          click: function ($event) {
                            return _vm.resetBooking()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.179")) + " ")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.saveFlag === "EDIT"
            ? _c("div", [
                _c("div", { staticClass: "mt10 flex_box" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button lg mr5",
                      on: {
                        click: function ($event) {
                          return _vm.goList()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ADMN030G020.017")))]
                  ),
                  ["SG"].includes(_vm.memberDetail.userCtrCd)
                    ? _c(
                        "a",
                        {
                          staticClass: "button lg mr5",
                          attrs: {
                            href: "/template/LOI for Pre-set Reefer.docx",
                            download: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.553")) + " ")]
                      )
                    : _vm._e(),
                  _vm.dgDclrBtnYn === "Y"
                    ? _c(
                        "a",
                        {
                          staticClass: "button lg mr5",
                          on: {
                            click: function ($event) {
                              return _vm.dgDeclarationPop()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "btn_icon deadline" }),
                          _vm._v("DG Declaration Download"),
                        ]
                      )
                    : _vm._e(),
                  _vm.lang === "KOR"
                    ? _c("div", [
                        _vm.regParams.bkgStsCd === "01"
                          ? _c(
                              "a",
                              {
                                staticClass: "button lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.shipmentFinishPop()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass: "btn_icon deadline",
                                }),
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.228")) + " "),
                              ]
                            )
                          : _vm._e(),
                        _vm.delayYn === "Y"
                          ? _c(
                              "a",
                              {
                                staticClass: "button lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.delayNoticePop()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "btn_icon delay" }),
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.229")) + " "),
                              ]
                            )
                          : _vm._e(),
                        _vm.vslRollOverYn === "Y"
                          ? _c(
                              "a",
                              {
                                staticClass: "button lg",
                                on: {
                                  click: function ($event) {
                                    return _vm.vesselChangePop()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "btn_icon change" }),
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.230")) + " "),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.vueMode,
                          expression: "vueMode",
                        },
                      ],
                      staticClass: "button lg mr5",
                      on: {
                        click: function ($event) {
                          return _vm.templateSavePop()
                        },
                      },
                    },
                    [_vm._v(" Template Save ")]
                  ),
                  _vm.regParams.bkgStsCd !== "03"
                    ? _c("div", { staticClass: "ml_auto" }, [
                        _vm.btnAreaVisible
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.saveBooking()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
                            )
                          : _vm._e(),
                        _vm.isSplitCombine("SPLIT")
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.fbBkgSplitCombine("SPLIT")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.231")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isSplitCombine("COMBINE")
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.fbBkgSplitCombine("COMBINE")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.232")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.usrCtrCd !== "VN" &&
                        _vm.usrCtrCd !== "JP" &&
                        _vm.usrCtrCd !== "ID" &&
                        _vm.usrCtrCd !== "HK" &&
                        _vm.usrCtrCd !== "IN" &&
                        _vm.btnAreaVisible &&
                        _vm.showCancelBtn() &&
                        !_vm.vslCancelReqYn() &&
                        _vm.srRest !== "C"
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelBooking()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.233")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        (_vm.usrCtrCd === "VN" ||
                          _vm.usrCtrCd === "JP" ||
                          _vm.usrCtrCd === "ID" ||
                          _vm.usrCtrCd === "HK" ||
                          _vm.usrCtrCd === "IN") &&
                        _vm.bookingCancelBtnVisible &&
                        _vm.showCancelBtn() &&
                        !_vm.vslCancelReqYn() &&
                        _vm.srRest !== "C"
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelBooking()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.233")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.btnAreaVisible &&
                        _vm.showCancelBtn() &&
                        _vm.vslCancelReqYn()
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelBooking()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.345")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.cancelReqYn === "Y" || _vm.invoiceReqYn === "Y"
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelReqDocCancel()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.253")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.regParams.bkgOversEditYn === "Y" &&
                        !_vm.btnAreaVisible
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg mr5",
                                on: {
                                  click: function ($event) {
                                    return _vm.saveBooking()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
                            )
                          : _vm._e(),
                        _vm.regParams.mtyPkupUsagYn !== "Y"
                          ? _c(
                              "a",
                              {
                                staticClass: "button blue lg",
                                on: {
                                  click: function ($event) {
                                    return _vm.createSR()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.CSBK100.234")))]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.regParams.bkgStsCd === "03"
                    ? _c("div", { staticClass: "ml_auto" }, [
                        _c("span", { staticClass: "color_error font_bold" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CMBA100.0127"))),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "doc_req_pop" },
        [
          _c(
            "win-layer-pop",
            [
              _vm.uploadComponent
                ? _c(_vm.uploadComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.uploadParam },
                    on: {
                      close: _vm.closeUploadPopup,
                      "set-dext-file-info": _vm.uploadCallbackFunc,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "etc_doc_pop" },
        [
          _c(
            "win-layer-pop",
            [
              _vm.etcUploadComponent
                ? _c(_vm.etcUploadComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.etcUploadParam },
                    on: {
                      close: _vm.closeEtcUploadPopup,
                      selectFunc: _vm.selectFunc,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.commonBLComponent
        ? _c(_vm.commonBLComponent, {
            ref: "commonBL",
            tag: "component",
            attrs: { "parent-info": _vm.commonBLParams },
            on: { callback: _vm.commonBLCallback },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "130px" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "130px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "130px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "c2" } }, [
      _c("span"),
      _vm._v("복화사용"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "chemicalY" } }, [
      _c("span"),
      _vm._v("Y"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mr10", attrs: { for: "chemicalN" } }, [
      _c("span"),
      _vm._v("N"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10.5%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "9.5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "color_red" }, [
      _vm._v(
        " All of CAS numbers shown on Chinese MSDS have to be entered on DG cargo details without omission When DG cargo is shipped to the restricted ports which force to provide CAS numbers."
      ),
      _c("br"),
      _vm._v(
        "Shipper (Reporter) should take all of the responsibilities caused by inaccurate information provided. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "80%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", { staticClass: "color_red font_bold" }, [
        _vm._v(" To Shanghai, a A MSDS is required. Please upload the file. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", { staticClass: "color_red font_bold" }, [
        _vm._v(
          " To S.China or Ningbo, a A MSDS in CHINESE is required. Please upload the file. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", { staticClass: "color_red" }, [
        _c("p", [
          _vm._v(
            "Sanction 대상 화물이 선적 되는 위험을 예방하기 위하여 선적하는 모든 화물에 대하여 제3자 기관을 통하여 Sanction 대상 여부의 검증을 실시하고 있습니다."
          ),
        ]),
        _c("p", [
          _vm._v(
            "원활한 검증을 위해 서류 제출시 하기 사항의 추가 입력을 요청드리오며, 허위 정보 입력시 Sanction 대상으로 확인될 위험성이 높으므로 정확한 정보가 입력될 수 있도록 협조를 요청드립니다. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "60%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { "border-left": "1px solid #e1e3eb" } }, [
        _vm._v("TEL"),
      ]),
      _vm._v(" "),
      _c("th", [_vm._v("EMAIL")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "85%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        {
          staticClass: "color_black font_medium",
          attrs: { id: "byte_dorOdrRmk" },
        },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        {
          staticClass: "color_black font_medium",
          attrs: { id: "byte_oprRmk" },
        },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }